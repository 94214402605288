(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-week-additions/assets/javascripts/weeks.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-week-additions/assets/javascripts/weeks.js');
"use strict";

const {
  useCallback
} = React;
const {
  Weeks: BaseWeek
} = svs.components.tipsen.couponAdditionsComponents;
const Weeks = () => {
  const getWeeksText = useCallback(weeks => {
    let text = '';
    weeks.forEach(week => {
      if (week === weeks[0]) {
        text += week;
      } else if (week === weeks[weeks.length - 1]) {
        text += " och ".concat(week);
      } else {
        text += ", ".concat(week);
      }
    });
    return text;
  }, []);
  const presentableWeek = useCallback(numberOfWeeks => "".concat(numberOfWeeks, " veck").concat(numberOfWeeks > 1 ? 'or' : 'a'), []);
  const getDialog = useCallback(weeks => "Spela samma rad flera veckor i f\xF6ljd. V\xE4lj mellan ".concat(getWeeksText(weeks), " veckor."), [getWeeksText]);
  return React.createElement(BaseWeek, {
    description: "V\xE4lj hur m\xE5nga veckor du vill spela din rad.",
    getDialog: getDialog,
    icon: "calendar-week",
    legend: "Antal veckor spelet ska p\xE5g\xE5",
    presentableWeek: presentableWeek,
    title: "Spela flera veckor"
  });
};
setGlobal('svs.components.tipsen.couponWeekAddition.Weeks', Weeks);

 })(window);